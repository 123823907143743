import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Resume",
  caps = "RESUME",
}
export interface Data {
  id: number;
  name: string;
  logo: string;
  color: string;
  created_at: string;
  updated_at: string;
}
@Module
export default class ResumeModule extends VuexModule {
  error = null;
  msg = null;
  relawan = 0;
  activity = 0;
  tps = {};
  ktanisasi = {};
  party1 = [];
  party2 = [];
  candidate = [];
  paramsCandidate = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }

  @Mutation
  ["SET_COUNT_RELAWAN_" + enums.caps](data) {
    this.relawan = data;
  }
  @Mutation
  ["SET_COUNT_ACTIVITY_" + enums.caps](data) {
    this.activity = data;
  }
  @Mutation
  ["SET_PERSENTAGE_TPS_" + enums.caps](data) {
    this.tps = [data.non_progress, data.progress];
  }
  @Mutation
  ["SET_PERSENTAGE_KTA_" + enums.caps](data) {
    this.ktanisasi = [data.non_progress, data.progress];
  }
  @Mutation
  ["SET_COUNT_PARTY1_" + enums.caps](data) {
    this.party1 = data.pilegs;
  }
  @Mutation
  ["SET_COUNT_PARTY2_" + enums.caps](data) {
    this.party2 = data.pilegs;
  }
  @Mutation
  ["SET_COUNT_CANDIDATE_" + enums.caps](data) {
    this.candidate = data;
  }
  @Mutation
  ["SET_PARAMS_CANDIDATE_" + enums.caps](data) {
    this.paramsCandidate = data;
  }

  @Action
  ["getPersentageTPS" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `parent_id=${data.territory}` : "";
      }
      ApiService.get(`api/v1/vote/tpses?${params}`)
        .then((res) => {
          this.context.commit(
            "SET_PERSENTAGE_TPS_" + enums.caps,
            res.data.data
          );
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getPersentageKTA" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `territory_id=${data.territory}` : "";
      }
      ApiService.get(`api/v1/political/kta-cadres?${params}`)
        .then((res) => {
          this.context.commit(
            "SET_PERSENTAGE_KTA_" + enums.caps,
            res.data.data
          );
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getCountRelawan" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/account/groups/", id)
        .then((res) => {
          this.context.commit(
            "SET_COUNT_RELAWAN_" + enums.caps,
            res.data.data.group.count
          );
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getCountActivity" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/account/activities/")
        .then((res) => {
          this.context.commit(
            "SET_COUNT_ACTIVITY_" + enums.caps,
            res.data.data.total
          );
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getCountParty" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `&territory_id=${data.territory}` : "";
        params += data.year ? `&years=${data.year}` : "";
      }
      ApiService.get(`api/v1/vote/pilegs?type=party${params}`)
        .then((res) => {
          if (data.type == 1) {
            this.context.commit(
              "SET_COUNT_PARTY1_" + enums.caps,
              res.data.data
            );
          } else if (data.type == 2) {
            this.context.commit(
              "SET_COUNT_PARTY2_" + enums.caps,
              res.data.data
            );
          }
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getCountCandidate" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `&territory_id=${data.territory}` : "";
        params += data.year ? `&years=${data.year}` : "";
        params += data.party ? `&party_id=${data.party}` : "";
      }
      ApiService.get(`api/v1/vote/pilegs?order=desc&type=candidate${params}`)
        .then((res) => {
          this.context.commit(
            "SET_COUNT_CANDIDATE_" + enums.caps,
            res.data.data
          );
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
