import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Kader",
  caps = "KADER",
}
export interface Data {
  id: number;
  name: string;
  profile: string;
  description: string;
  roleId: number;
  terroteryId: number;
  partyId: number;
  created_at: string;
}
@Module
export default class KaderModule extends VuexModule {
  error = null;
  msg = null;
  all = [
    {
      cadre: {
        id: 1,
        name: "Caleg A",
        profile: "image.png",
        description: "description for name Caleg A",
        cadre_role_id: 1,
        territory_id: 1,
        party_id: 1,
      },
      cadre_role: {
        name: "Caleg",
      },
      party: {
        name: "PKS",
        logo: "logo.png",
        colour: "yellow",
      },
      territory: {
        name: "Indonesia",
        parent_id: 0,
        level_id: 1,
        latitude: -6.310942,
        longitude: 106.7072304,
        count: 0,
      },
      territory_level: {
        name: "Country",
      },
    },
    {
      cadre: {
        id: 2,
        name: "Caleg B",
        profile: "image.png",
        description: "description for name Caleg B",
        cadre_role_id: 1,
        territory_id: 1,
        party_id: 1,
      },
      cadre_role: {
        name: "Caleg",
      },
      party: {
        name: "PKS",
        logo: "logo.png",
        colour: "yellow",
      },
      territory: {
        name: "Indonesia",
        parent_id: 0,
        level_id: 1,
        latitude: -6.310942,
        longitude: 106.7072304,
        count: 0,
      },
      territory_level: {
        name: "Country",
      },
    },
  ];
  detail = [];
  id = null;
  page = 1;
  limit = 10;
  totalPage = 1;

  // get FindKader() {
  //   return (id) => this.all.find((data: Data) => data.id === id);
  // }

  // get FilterKader() {
  //   return (id: number) => {
  //     this.all.filter((data: Data) => data.id === id);
  //   };
  // }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.cadre;
    this.limit = data.limit;
    this.page = data.page;
    this.totalPage = data.total;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }
  @Mutation
  ["SET_ID_" + enums.caps](data) {
    this.id = data;
  }
  @Mutation
  ["SET_PAGE_" + enums.caps](data) {
    this.page = data;
  }
  @Mutation
  ["SET_LIMIT_" + enums.caps](data) {
    this.limit = data;
  }

  @Action
  ["All" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `api/v1/political/cadres?page=${this.page}&limit=${this.limit}`
      )
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["store" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v1/political/cadres", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["detail" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/political/cadres/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.cadre);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["update" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("api/v1/political/cadres/" + req.id, req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["destroy" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("api/v1/political/cadres/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
