import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "AccountConfirm",
  caps = "ACCOUNT_CONFIRM",
}
export interface Data {
  id: number;
  name: string;
  email: string;
  telp: string;
  rt: string;
  rw: string;
  kelurahan: string;
  kecamatan: string;
  status: string;
}
@Module
export default class AccountConfirmModule extends VuexModule {
  error = null;
  msg = null;
  all = [
    {
      id: 1,
      name: "Tester User",
      email: "tester@uwu.com",
      telp: "088999223415",
      rt: "005",
      rw: "007",
      kelurahan: "Kebon Pala",
      kecamatan: "Makasar",
      status: "pending",
    },
    {
      id: 2,
      name: "Tester BBB",
      email: "bbb@uwu.com",
      telp: "088999224423",
      rt: "007",
      rw: "005",
      kelurahan: "Kebon Pala",
      kecamatan: "Makasar",
      status: "pending",
    },
    {
      id: 3,
      name: "Sudah ACC",
      email: "yes@acc.com",
      telp: "088999224433",
      rt: "005",
      rw: "005",
      kelurahan: "Kebon Pala",
      kecamatan: "Makasar",
      status: "approve",
    },
  ];
  detail = [];
  id = null;

  get FindAccountConfirm() {
    return (id) => this.all.find((data: Data) => data.id === id);
  }

  get FilterAccountConfirm() {
    return (id: number) => {
      this.all.filter((data: Data) => data.id === id);
    };
  }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }
  @Mutation
  ["SET_ID_" + enums.caps](data) {
    this.id = data;
  }

  @Action
  ["All" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq", req)
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["store" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq/store", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["update" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq/update", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["destroy" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq/destroy", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
