import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "KTACandidateDashboard",
  caps = "KTACANDIDATEDASHBOARD",
}

@Module
export default class KTACandidateDashboardModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  detail = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.kta_candidates;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }

  @Action
  ["detail" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/political/kta-candidates/${data.kta_candidate_id}`)
        .then((res) => {
          this.context.commit("SET_DETAIL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["All" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/political/kta-candidates/`)
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
