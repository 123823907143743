import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Comparison",
  caps = "COMPARISON",
}
export interface Data {
  id: number;
  name: string;
  logo: string;
  color: string;
  created_at: string;
  updated_at: string;
}
@Module
export default class ComparisonModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  party = [];
  party1 = [];
  party2 = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data;
  }
  @Mutation
  ["SET_PARTY_" + enums.caps](data) {
    this.party = data.parties;
  }
  @Mutation
  ["SET_COUNT_PARTY1_" + enums.caps](data) {
    this.party1 = data.pilegs;
  }
  @Mutation
  ["SET_COUNT_PARTY2_" + enums.caps](data) {
    this.party2 = data.pilegs;
  }

  @Action
  ["All" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `&territory_id=${data.territory}` : "";
      }
      ApiService.get(`api/v1/vote/pilegs?type=heatmaps&years=2019${params}`)
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getParty" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/vote/parties/`)
        .then((res) => {
          this.context.commit(
            "SET_PARTY_" + enums.caps,
            res.data.data
          );
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getCountParty" + enums.name](data) {
    let result = 0
    // return new Promise<void>((resolve, reject) => {
    let params = "";
    if (data) {
      params += data.territory ? `&territory_id=${data.territory}` : "";
      params += data.year ? `&years=${data.year}` : "";
      params += data.party ? `&party_id=${data.party}` : "";
    }
    ApiService.get(`api/v1/vote/pilegs?type=party${params}`)
      .then((res) => {
        // resolve();
        // console.log(res.data);
        if (res.data.data.pilegs[0]) {
          result = res.data.data.pilegs[0].party.count
        }
        result = 0

      })
      .catch((err) => {
        this.context.commit(
          "SET_ERROR_" + enums.caps,
          err.response.data.message
        );
        // reject();
      });
      console.log(result);
      
    // });
  }
}
