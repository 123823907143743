import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "KaderCategory",
  caps = "KADERCATEGORY",
}
export interface Data {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
@Module
export default class KaderCategoryModule extends VuexModule {
  error = null;
  msg = null;
  all = [
    {
      id: 1,
      name: "KaderCategory A",
      created_at: "2020-07-26 06:09:45",
      updated_at: "2020-07-26 06:09:45",
    },
    {
      id: 2,
      name: "KaderCategory B",
      created_at: "2020-07-26 06:09:45",
      updated_at: "2020-07-26 06:09:45",
    },
    {
      id: 3,
      name: "KaderCategory C",
      created_at: "2020-07-26 06:09:45",
      updated_at: "2020-07-26 06:09:45",
    },
  ];
  detail = [];
  id = null;
  page = 1;
  limit = 10;
  totalPage = 1;

  get FindKaderCategory() {
    return (id) => this.all.find((data: Data) => data.id === id);
  }

  get FilterKaderCategory() {
    return (id: number) => {
      this.all.filter((data: Data) => data.id === id);
    };
  }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.cadre_role;
    this.limit = data.limit;
    this.page = data.page;
    this.totalPage = data.total;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }
  @Mutation
  ["SET_ID_" + enums.caps](data) {
    this.id = data;
  }
  @Mutation
  ["SET_PAGE_" + enums.caps](data) {
    this.page = data;
  }
  @Mutation
  ["SET_LIMIT_" + enums.caps](data) {
    this.limit = data;
  }

  @Action
  ["All" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `api/v1/political/cadre_roles?page=${this.page}&limit=${this.limit}`
      )
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["store" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v1/political/cadre_roles", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["detail" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/political/cadre_roles/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.cadre_role);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["update" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("api/v1/political/cadre_roles/" + req.id, req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["destroy" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("api/v1/political/cadre_roles/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
