import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Result",
  caps = "RESULT",
}
export interface Data {
  id: number;
  name: string;
  logo: string;
  color: string;
  created_at: string;
  updated_at: string;
}
@Module
export default class ResultModule extends VuexModule {
  error = null;
  msg = null;
  party = [];
  candidate = [];
  tps = [];
  detailTps = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_PARTY_" + enums.caps](data) {
    this.party = data.pilegs;
  }
  @Mutation
  ["SET_CANDIDATE_" + enums.caps](data) {
    this.candidate = data;
  }
  @Mutation
  ["SET_TPS_" + enums.caps](data) {
    this.tps = data.tpses;
  }
  @Mutation
  ["SET_DETAIL_TPS_" + enums.caps](data) {
    this.detailTps = data;
  }

  @Action
  ["getParty" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `&territory_id=${data.territory}` : "";
        params += data.tps ? `&tps_id=${data.tps}` : "";
      }
      ApiService.get(`api/v1/vote/pilegs?type=party&years=2019${params}`)
        .then((res) => {
          this.context.commit("SET_PARTY_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getCandidate" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `&territory_id=${data.territory}` : "";
        params += data.tps ? `&tps_id=${data.tps}` : "";
      }
      ApiService.get(`api/v1/vote/pilegs?type=candidate&years=2019${params}`)
        .then((res) => {
          this.context.commit("SET_CANDIDATE_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getTPS" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      let params = "";
      if (data) {
        params = data.territory ? `&territory_id=${data.territory}` : "";
      }
      ApiService.get(`api/v1/vote/tpses?page=1${params}`)
        .then((res) => {
          this.context.commit("SET_TPS_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getDetailTPS" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/vote/tpses/${data.tps}`)
        .then((res) => {
          this.context.commit("SET_DETAIL_TPS_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
