import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Territory",
  caps = "TERRITORY",
}
export interface Data {
  territory: {
    id: number;
    name: string;
    parent_id: number;
    level_id: number;
    latitude: number;
    longitude: number;
    count: number;
  };
  territory_level: {
    name: string;
  };
}

export interface Params {
  parent_id: any;
}
@Module
export default class TerritoryModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  kecamatan = [];
  kelurahan = [];
  rw = [];
  tps = [];
  detail = [];
  id = null;
  page = 1;
  limit = 10;
  totalPage = 1;

  get FindKecamatan() {
    return (id: number) =>
      this.kecamatan.find((data: Data) => data.territory.id === id);
  }

  // get FilterTerritory() {
  //   return (id: number) => {
  //     this.all.filter((data: Data) => data.id === id);
  //   };
  // }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    if (data.type == 4) {
      this.kecamatan = data.territories;
    } else if (data.type == 5) {
      this.kelurahan = data.territories;
    } else if (data.type == 6) {
      this.rw = data.territories;
    } else if (data.type == 7) {
      this.tps = data.territories;
    } else {
      this.all = data.territories;
      this.limit = data.limit;
      this.page = data.page;
      this.totalPage = data.total;
    }
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }
  @Mutation
  ["SET_ID_" + enums.caps](data) {
    this.id = data;
  }
  @Mutation
  ["SET_PAGE_" + enums.caps](data) {
    this.page = data;
  }
  @Mutation
  ["SET_LIMIT_" + enums.caps](data) {
    this.limit = data;
  }

  @Action
  ["All" + enums.name](data) {
    let params = "";

    if (data) {
      params = data.parent_id ? `&parent_id=${data.parent_id}` : "";
      params += data.level_id ? `&level_id=${data.level_id}` : "";
    }
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `api/v1/master/territories?page=${this.page}&limit=${this.limit}${params}`
      )
        .then((res) => {
          res.data.data.type = data.type;
          this.context.commit("SET_ALL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["store" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v1/master/territories", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["detail" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/master/territories/", id)
        .then((res) => {
          this.context.commit("SET_DETAIL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["update" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("api/v1/master/territories/" + req.id, req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["destroy" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("api/v1/master/territories/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
