import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Attributions",
  caps = "ATTRIBUTIONS",
}
// export interface Data {
//   id: number;
//   name: string;
//   profile: string;
//   description: string;
//   roleId: number;
//   terroteryId: number;
//   partyId: number;
//   created_at: string;
// }
@Module
export default class AttributionsModule extends VuexModule {
  error = null;
  msg = null;
  all = [
    {
      attribution: {
        name: "baliho",
        icon: "baliho.png",
      },
    },
    {
      attribution: {
        name: "spanduk",
        icon: "spanduk.png",
      },
    },
  ];
  detail = [];
  id = null;
  page = 1;
  limit = 10;
  totalPage = 1;

  // get FindTerritory() {
  //   return (id) => this.all.find((data: Data) => data.id === id);
  // }

  // get FilterTerritory() {
  //   return (id: number) => {
  //     this.all.filter((data: Data) => data.id === id);
  //   };
  // }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.attributions;
    this.limit = data.limit;
    this.page = data.page;
    this.totalPage = data.total;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }
  @Mutation
  ["SET_ID_" + enums.caps](data) {
    this.id = data;
  }
  @Mutation
  ["SET_PAGE_" + enums.caps](data) {
    this.page = data;
  }
  @Mutation
  ["SET_LIMIT_" + enums.caps](data) {
    this.limit = data;
  }

  @Action
  ["All" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `api/v1/master/attributions?page=${this.page}&limit=${this.limit}`
      )
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["store" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v1/master/attributions", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["detail" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/master/attributions/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.attributions);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["update" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("api/v1/master/attributions/" + req.id, req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["destroy" + enums.name](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("api/v1/master/attributions/" + id)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
