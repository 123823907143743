import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "KTACountCandidateDashboard",
  caps = "KTACOUNTCANDIDATEDASHBOARD",
}

@Module
export default class KTACountCandidateDashboardModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  detail = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.kta_count_candidates;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }

  @Action
  ["All" + enums.name](data) {
    let params = "";
    // // console.log(data);

    if (data) {
      params = data.kta_candidate_id
        ? `?kta_candidate_id=${data.kta_candidate_id}`
        : params;
    }
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/political/kta-count-candidates${params}`)
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
