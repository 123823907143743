import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Volunteer",
  caps = "VOLUNTEER",
}
export interface Data {
  id: number;
  accountId: number;
  koorId: number;
  kaderId: number;
  terroteryId: number;
  approval: string;
  created_at: string;
  updated_at: string;
}
@Module
export default class VolunteerModule extends VuexModule {
  error = null;
  msg = null;
  all = [
    {
      id: 1,
      accountId: 1,
      koorId: 1,
      kaderId: 1,
      terroteryId: 1,
      approval: "y",
      created_at: "2020-07-26 06:09:45",
      updated_at: "2020-07-26 06:09:45",
    },
    {
      id: 2,
      accountId: 2,
      koorId: 2,
      kaderId: 2,
      terroteryId: 2,
      approval: "y",
      created_at: "2020-07-26 06:09:45",
      updated_at: "2020-07-26 06:09:45",
    },
    {
      id: 3,
      accountId: 3,
      koorId: 3,
      kaderId: 3,
      terroteryId: 3,
      approval: "n",
      created_at: "2020-07-26 06:09:45",
      updated_at: "2020-07-26 06:09:45",
    },
  ];
  detail = [];
  id = null;

  get FindVolunteer() {
    return (id) => this.all.find((data: Data) => data.id === id);
  }

  get FilterVolunteer() {
    return (id: number) => {
      this.all.filter((data: Data) => data.id === id);
    };
  }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }
  @Mutation
  ["SET_ID_" + enums.caps](data) {
    this.id = data;
  }

  @Action
  ["All" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq", req)
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["store" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq/store", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["update" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq/update", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["destroy" + enums.name](req) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/faq/destroy", req)
        .then((res) => {
          this.context.commit("SET_MSG_" + enums.caps, res.data.message);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
