import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "ZoningDashboard",
  caps = "ZONINGDASHBOARD",
}

@Module
export default class ZoningDashboardModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  detail = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.zonations;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }

  @Action
  ["All" + enums.name](data) {
    const params_range_age = `&range_age_id=${data.range_age_id}`;
    let params_territory = "";
    if (data) {
      params_territory = data.territory_id
        ? `&parent_id=${data.territory_id}`
        : params_territory;
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `api/v1/political/zonations?page=1${params_range_age}${params_territory}`
      )
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
